.job-skeleton {
    display: flex;
    justify-content: space-around;

    &--circle > span {
        width: 90px !important;
        height: 90px !important;
    }

    &--line > span {
        width: 100px !important;
        height: 14px !important;
        margin-top: 10px !important;
    }

    &--line-two > span {
        width: 70px !important;
        height: 14px !important;
        margin-top: 4px !important;
        margin-bottom: 40px !important;
    }
}

@include media-breakpoint-down(sm) {
    .job-skeleton {
        display: flex;
        justify-content: space-around;

        &--circle > span {
            width: 50px !important;
            height: 50px !important;
        }

        &--line > span {
            width: 50px !important;
            height: 14px !important;
            margin-top: 10px !important;
        }

        &--line-two > span {
            width: 40px !important;
            height: 14px !important;
            margin-top: 2px !important;
            margin-bottom: 20px !important;
        }
    }
}
