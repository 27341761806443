.modal {
	.modal-dialog {
		max-width: 100%;
		margin: 0 5%;
	}

	.modal-content {
		border: none;
		border-radius: 0;
		background-color: transparent;
	}
}

.fullscreen {
	margin: 0 !important;
}

.full-width {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

@include media-breakpoint-down(sm) {
	.modal {
		.modal-dialog {
			margin: 0 0;
		}
	}

	.address-mobile-view {
		.modal-dialog {
			margin: 0 !important;
			bottom: 0 !important;
		}
	}
}