.yc-job-detail {

    .top-wrap {
        padding: 0 16px;

        .price-txt {
            margin: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: start;
            color: #302403;

            strong {
                font-weight: 700;
                font-size: 26px;
            }
        }

        a {
            position: relative;
            padding-right: 16px;
            font-size: 12px;
            line-height: 15px;
            font-weight: $font_600;
            color: #b59436;
            text-decoration: none;
            cursor: pointer;

            &::after {
                // content: "\203A";
                content: '';
                position: absolute;
                width: 10px;
                height: 8px;
                background: url("/assets/images/icons/dw-ar-lg-yellow.png") no-repeat;
                background-size: contain;
                right: 0;
                top: 1px;
                bottom: 0;
                margin: auto;
            }
        }

        .hide-job {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .sec-break {
        color: #CECECE;
        opacity: 1;
        height: 0.5px;
        margin: 32px 0 16px;
    }

    .scroll-wrap {
        max-height: calc(100vh - 360px);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin-bottom: 14px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .items-sec {
        padding: 0 16px;
        margin-bottom: 16px;
        // max-height: calc(100vh - 460px);
        // overflow-y: scroll;
        // -ms-overflow-style: none;
        // scrollbar-width: none;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        .item {
            padding: 16px 8px;
            background: #FDF7E7;
            border: 0.5px solid #CECECE;
            border-radius: 4px;
            margin-bottom: 8px;

            .title-line {
                h6 {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    color: rgba(48, 36, 3, 0.8);
                    padding: 0;
                    margin-bottom: 12px;
                }

                .title {
                    text-align: left;
                    margin-right: 16px;
                }

                .price {
                    text-align: right;
                    min-width: fit-content;
                }
            }

            .addon-line {
                margin-bottom: 12px;
                
                .addon-txt {
                    font-weight: 500;
                    color: rgba(48, 36, 3, 0.6);
                    margin-bottom: 6px;

                    b {
                        font-weight: 600;
                        color: rgba(48, 36, 3, 0.8);
                    }
                }

                .price {
                    min-width: fit-content;
                }
            }

            .btn-line {
                p {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                    text-decoration: underline;
                    color: #0E3DBE;
                    cursor: pointer;
                    margin: 0;
                }

                .remove-btn {
                    color: #CF492B;
                }
            }
        }
    }

    .other-info-sec {
        padding: 0 16px 4px;
        // margin-bottom: 12px;
        // max-height: 300px;
        // overflow-y: scroll;
        // -ms-overflow-style: none;
        // scrollbar-width: none;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        .item {
            margin-bottom: 20px;

            h6 {
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                color: rgba(48, 36, 3, 0.6);
            }

            .value {
                background: #F6F5F3;
                border-radius: 4px;
                padding: 12px 8px;

                .left-sec {
                    margin-right: 12px;

                    b {
                        color: tomato;
                        font-weight: 600;
                    }
                }

                p {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    color: rgba(48, 36, 3, 0.8);
                    margin: 0;
                    overflow: auto;
                }

                .edit-btn {
                    font-weight: 500;
                    color: #0E3DBE !important;
                    cursor: pointer;

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    .pricing-sec {
        padding: 0 16px 4px;

        .price-line {
            h6 {
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: rgba(48, 36, 3, 0.8);
                padding: 0;
                margin-bottom: 12px;
            }

            .title {
                text-align: left;
                margin-right: 16px;
            }

            .price {
                text-align: right;
                font-weight: 700;
                min-width: fit-content;
            }
        }
    }
}