pagination-controls {
    .ngx-pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 26px 0 0;
        padding: 0;

        > li {
            margin: 0;

            &.current {
                padding: 0 4px;
                background: transparent;
            }

            > span {
                font-weight: $font_600;
                font-size: 16px;
                line-height: 20px;
                color: #302403;
            }

            > a {
                padding: 0 4px;

                &:hover {
                    background: transparent;
                }

                > span {
                    font-weight: $font_400;
                    font-size: 13px;
                    line-height: 16px;
                    color: rgba(48, 36, 3, 0.4);
                }
            }
        }

        .pagination-previous,
        .pagination-next {
            padding: 0;
        }

        .pagination-previous,
        .pagination-previous > a,
        .pagination-next,
        .pagination-next > a {
            &:after,
            &:before {
                content: none !important;
            }
        }
        .pagination-previous > span,
        .pagination-previous > a,
        .pagination-next > span,
        .pagination-next > a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            padding: 0;
            background: #fcf3d9 !important;
            border-radius: 5px;
            font-size: 0;

            > span {
                position: relative !important;
                font-size: 0;
                clip: unset;
                @extend .sprite;
            }
        }

        .pagination-previous {
            margin-right: 6px;

            > span > span,
            > a > span {
                @extend %task-paginate-left;
            }
        }

        .pagination-next {
            margin-left: 6px;

            > span > span,
            > a > span {
                @extend %task-paginate-right;
            }
        }
    }
}
