.faq-accordion {
    padding-top: 16px !important;

    .panel {
        margin-bottom: 16px;
    }

    .panel-open .accordion-toggle:after {
        transform: rotate(-180deg) !important;
    }

    .card {
        border: 1px solid var(--whiteSmoke);
        border-radius: 4px;

        .card-header {
            border: none;
            background-color: var(--white);
            border-radius: 0;

            .accordion-toggle {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;

                .btn {
                    width: calc(100% - 12px);
                    font-weight: $font_600;
                    color: var(--maire);
                    text-decoration: none;
                    box-shadow: none;
                    padding: 0;
                    border: none;
                    text-align: left;
                    padding-right: 20px;
                    word-break: break-word;
                }

                &:after {
                    content: "";
                    @extend .sprite;
                    @extend %accordion-arrow;
                    transform: rotate(0);
                }
            }
        }

        .card-body {
            p,
            li,
            a {
                color: rgba(48, 36, 3, 0.8);
            }

            p,
            ul ol {
                margin-bottom: 11px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .faq-accordion {
        padding: 0 57px;

        .card {
            .card-header {
                padding: 24px 33px 24px 23px;

                .accordion-toggle {
                    .btn {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }

            .card-body {
                padding: 0px 40px 16px 24px;
                margin-top: -7px;

                ul,
                ol {
                    padding-left: 76px;
                    margin-top: -3px;
                    margin-bottom: 11px;
                }

                p,
                li,
                a {
                    font-size: 15px;
                    line-height: 26px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .faq-accordion {
        .card {
            .card-header {
                padding: 21px 17px 21px 15px;

                .accordion-toggle {
                    .btn {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }

            .card-body {
                padding: 0 15px 16px;
                margin-top: -7px;

                p,
                li,
                a {
                    font-size: 13px;
                    line-height: 24px;
                }
            }
        }
    }
}
