aw-wizard {
    aw-wizard-navigation-bar {
        &.horizontal {
            ul.steps-indicator {
                width: 609px;
                margin-left: 158px;
                margin-bottom: 36px;

                li {
                    a {
                        .label {
                            padding-top: 22px;
                        }
                    }
                }
            }

            &.small {
                ul.steps-indicator {
                    li {
                        .step-indicator {
                            width: 20px;
                            height: 20px;
                            background-color: #dddddd;
                            border: 2px solid transparent;
                        }

                        &.navigable {
                            a {
                                &:hover {
                                    .step-indicator {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }

                            &.done {
                                a:hover {
                                    .step-indicator {
                                        background-color: #edb111;
                                        opacity: 0.8;
                                    }
                                }
                            }
                        }

                        &.current {
                            .step-indicator {
                                background-color: #dddddd !important;
                                border: 2px solid #edb111;
                            }

                            .label {
                                font-weight: $font_600;
                            }
                        }

                        &.done {
                            .step-indicator {
                                background-color: #edb111;
                            }
                        }

                        &:not(:last-child):after {
                            background-color: #f2f2f2;
                            height: 4px;
                            width: calc(100% - 20px);
                            top: -5px;
                            left: calc(50% + 13px);
                        }
                    }
                }
            }
        }

        ul {
            &.steps-indicator {
                li {
                    a {
                        .label {
                            line-height: 15px;
                            font-size: 12px;
                            text-transform: capitalize;
                            color: var(--black);
                            font-weight: $font_400;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    aw-wizard {
        aw-wizard-navigation-bar {
            &.horizontal {
                ul.steps-indicator {
                    width: calc(100% - 158px);
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    aw-wizard {
        aw-wizard-navigation-bar {
            &.horizontal {
                ul.steps-indicator {
                    width: calc(100% - 54px);
                    margin-left: 54px;
                    margin-bottom: 15px;
                }
            }

            ul {
                &.steps-indicator {
                    li {
                        a {
                            .label {
                                line-height: 12px;
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
