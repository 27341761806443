// Body
// $font-family-base: "Montserrat", sans-serif !default;
$font-family-base: "Metropolis", sans-serif !default;
$body-color: #425563 !default;
$body-bg: #ffffff !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    // xl: 1260px,
    // xl: 1194px,
    // xl: 1200px,
    xl: 1198px,
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-responsive-font-sizes: true;
