.slick-slider {

    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-track {
        display: flex;
    }

    .slick-arrow {
        width: 48px;
        height: 48px;
        background: var(--white);
        border: 0.5px solid #CECECE;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 12px;
            background: url("#{$icon-slick-arrow}") no-repeat;
            background-size: contain;
            background-position: center;
        }

        &:before {
            content: none;
        }

        &.slick-prev {
            &:after {
                transform: scaleX(-1);
            }
        }

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-dots {
        bottom: 0;
        padding-bottom: 15px;

        li {
            width: 15px;
            height: 15px;
            margin: 0;

            button {
                width: 15px;
                height: 15px;
                padding: 0;

                &:before {
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    line-height: 17px;
                    color: var(--yellow);
                }
            }

            &.slick-active button:before {
                color: var(--yellow);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .slick-slider {
        .slick-arrow {
            &.slick-prev {
                left: -30px;
            }

            &.slick-next {
                right: -30px;
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .slick-slider {
        .slick-arrow {
            &.slick-prev {
                left: -70px;
            }

            &.slick-next {
                right: -70px;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .slick-slider {
        .slick-arrow {
            width: 50px;
            height: 50px;

            &.slick-prev {
                left: -20px;
            }

            &.slick-next {
                right: -20px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .slick-slider {
        .slick-arrow {
            width: 40px;
            height: 40px;

            &:after {
                width: 8px;
                height: 14px;
            }

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                right: 10px;
            }
        }
    }
}