/*
 * BOOTSTRAP
 *
 * -- include bootstrap setting|configs above the bootstrap include.
 * -- https://getbootstrap.com/docs/5.0/getting-started/theming/
 */
@import "overwrite/bootstrap";

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// angular-archwizard
@import "~angular-archwizard/styles/archwizard.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

// Helpers
@import "helpers/functions", "helpers/variables", "helpers/mixins";

// Basics
@import "basics/font-face", "basics/typography", "basics/common", "basics/buttons", "basics/modal", "basics/image",
  "basics/form", "basics/carousel", "basics/spinner", "basics/timepicker", "basics/checkbox", "basics/snackbar";

// Pages
@import "pages/promotions", "pages/customer-reviews", "pages/wizard", "pages/price-panel", "pages/details-panel",
  "pages/otp", "pages/jobs-skeleton", "pages/common-page", "pages/breadcrumb", "pages/tabset", "pages/mytask-modal",
  "pages/pagination", "pages/partner-slider", "pages/faq-tab", "pages/faq-accordion", "pages/search-panel",
  "pages/copy-link", "pages/how-works", "pages/job-detail";

//Custom Toastr
.ngx-toastr {
  border-radius: 12px !important;
  border: 1px solid white;
  padding: 13px 15px 10px 53px !important;
  background-position: 6px center !important;
  background-size: 42px !important;
  .toast-title {
    font-family: Metropolis;
    font-size: 15px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: #302403;
    margin-bottom: 3px;
  }
  .toast-message {
    font-family: Metropolis;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #302403;
    max-width: 199px;
  }
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px!important;
    .toast-close-button {
      display: block !important;
      opacity: 1!important;
    }
  }
  .toast-close-button {
    display: none;
    top: 0.25em;
    height: 19px;
    width: 19px;
    border-radius: 5px;
    color: #1e1e1e;
    background: #ffffff !important;
    span {
      position: relative;
      top: -5px;
    }
  }
  &.toast-success {
    background: #edffef;
    border-color: #02c00f;
    background-image: url("../assets/images/icons/success.png");
  }
  &.toast-info {
    background: #e5f4ff;
    border-color: #026ec0;
    background-image: url("../assets/images/icons/info.png");
  }
  &.toast-warning {
    background: #ffffe5;
    border-color: #edb111;
    background-image: url("../assets/images/icons/warning.png");
  }
  &.toast-error {
    background: #fff4f4;
    border-color: #c00202;
    background-image: url("../assets/images/icons/danger.png");
  }
}
