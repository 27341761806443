.spinner-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  background-color: rgba(0, 0, 0, 0.459);
  display: flex;
  align-items: center;
  justify-content: center;
  &.full-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  &:before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 8px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
  }

  .spinner {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--yellow);
    background: -moz-linear-gradient(left, var(--yellow) 10%, rgba(0, 0, 0, 0) 42%);
    background: -webkit-linear-gradient(left, var(--yellow) 10%, rgba(0, 0, 0, 0) 42%);
    background: -o-linear-gradient(left, var(--yellow) 10%, rgba(0, 0, 0, 0) 42%);
    background: -ms-linear-gradient(left, var(--yellow) 10%, rgba(0, 0, 0, 0) 42%);
    background: linear-gradient(to right, var(--yellow) 10%, rgba(0, 0, 0, 0) 42%);
    -webkit-animation: loading 1.4s infinite linear;
    animation: loading 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    &.spinner:before {
      width: 50%;
      height: 50%;
      background: var(--yellow);
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
    &.spinner:after {
      background: var(--white);
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@include media-breakpoint-down(xl) {
  .spinner-wrapper {
    height: calc(100vh - 75px - 150px);
  }
}

@include media-breakpoint-down(sm) {
  .spinner-wrapper {
    height: calc(100vh - 73px - 150px);
  }
}
