#yc-snackbar {
    visibility: hidden;
    display: none;
    width: 342px;
    margin-left: -171px;
    height: 48px;
    color: #008000;
    padding: 8px 24px;
    position: fixed;
    z-index: 9999;
    left: 50%;
    top: 30px;
    background: #E3F2E3;
    border: 0.5px solid #008000;
    box-shadow: 0px 0px 25px rgba(0, 128, 0, 0.1);
    border-radius: 6px;
    align-items: center;

    .left-sec {
        font-size: 20px;
        margin-right: 8px;
        margin-top: 3px;
    }

    .right-sec {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
    }
}

#yc-snackbar.show {
    visibility: visible;
    display: flex;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#yc-snackbar.hide {
    visibility: hidden;
    display: none;
}


/* Snackbar fade in and out animations */
@-webkit-keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}

@media screen and (max-width: 346px) {
    #yc-snackbar {
        width: 268px;
        margin-left: -134px;
    }
}