.yc-btn {
    min-width: 214px;
    height: 40px;
    background: var(--yellow);
    border-radius: 4px;
    font-weight: $font_600;
    color: var(--white);
    border: none;
    padding: 10px;
    transition: all 0.4s ease;

    &:hover {
        opacity: 0.7;
    }
}

.yc-link-btn {
    position: relative;
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    font-weight: $font_700;
    background: #fdf7e6;
    border-radius: 24px;
    padding: 12px 50px 13px 35px;
    text-decoration: none;
    transition: all 0.4s ease;

    &::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 12px;
        background: url("#{$icon-right-arrow-dark}") no-repeat;
        background-size: contain;
        top: 0;
        right: 24px;
        bottom: 0;
        margin: auto;
    }

    &:hover {
        color: var(--black);
        opacity: 0.7;
    }
}

.yc-icon-btn {
    display: inline-block;
    height: 41px;
    background: #fdf7e6;
    border-radius: 8px;
    padding: 13px 16px 10px 52px;
    transition: all 0.4s ease;
    cursor: pointer;

    &::after {
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        background: url("#{$icon-chat}") no-repeat;
        background-size: contain;
        top: 5px;
        left: 20px;
        bottom: 0;
        margin: auto;
    }

    &:hover {
        opacity: 0.7;
    }
}

.yc-btn-default {
    min-width: 246px;
    height: 40px;
    background: var(--yellow);
    border-radius: 4px;
    color: var(--black);
    border: none;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    transition: all 0.4s ease;

    &:hover {
        opacity: 0.7;
    }
}

@include media-breakpoint-down(lg) {
    .yc-btn {
        min-width: 150px;
        height: inherit;
        font-size: 12px;
        padding: 8px;
        line-height: inherit;
    }

    .yc-link-btn {
        font-size: 12px;
        line-height: inherit;
        padding: 10px 30px;

        &:after {
            right: 15px;
        }
    }

    .yc-btn-default {
        min-width: 150px;
        height: inherit;
        padding: 8px;
        font-size: 12px;
        line-height: inherit;
    }
}
