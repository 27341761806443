/* %font-MontserratBlack {
	font-family: $font_family_montserrat;
	font-weight: $font_900;
	font-style: normal;
}

%font-MontserratExtrabold {
	font-family: $font_family_montserrat;
	font-weight: $font_800;
	font-style: normal;
}

%font-MontserratBold {
	font-family: $font_family_montserrat;
	font-weight: $font_700;
	font-style: normal;
}

%font-MontserratSemibold {
	font-family: $font_family_montserrat;
	font-weight: $font_600;
	font-style: normal;
}

%font-MontserratMedium {
	font-family: $font_family_montserrat;
	font-weight: $font_500;
	font-style: normal;
}

%font-MontserratRegular {
	font-family: $font_family_montserrat;
	font-weight: $font_400;
	font-style: normal;
}

%font-MontserratLight {
	font-family: $font_family_montserrat;
	font-weight: $font_300;
	font-style: normal;
}

%font-MontserratExtraLight {
	font-family: $font_family_montserrat;
	font-weight: $font_200;
	font-style: normal;
}

%font-MontserratThin {
	font-family: $font_family_montserrat;
	font-weight: $font_100;
	font-style: normal;
} */

/* Metropolis - Thin - 100 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Thin.woff2') format('woff2');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Extra Light - 200 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-ExtraLight.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Light - 300 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Regular - 400 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Medium - 500 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Semi Bold - 600 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Bold - 700 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Extra Bold - 800 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-ExtraBold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

/* Metropolis - Black - 900 */
@font-face {
	font-family: 'Metropolis';
	src: url('../../assets/fonts/Metropolis-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

// IMPACT FONT [YC LOGO TEXT]
@font-face {
	font-family: "Impact";
	src: local('Impact'), url(../../assets/fonts/impact.ttf) format("truetype");
}

// Outfit Font [YellowZap Logo Text]
@font-face {
	font-family: "Outfit";
	src: local('Outfit'), url(../../assets/fonts/Outfit-Bold.ttf) format("truetype");
  font-display: swap;
}

/* montserrat-100 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 100;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-100.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-100.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-200 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 200;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-200.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-200.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-300 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 300;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-300.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-300.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-regular - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 400;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-regular.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-regular.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-500 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 500;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-500.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-500.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-600 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 600;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-600.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-600.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-700 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 700;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-700.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-700.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-800 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 800;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-800.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-800.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* montserrat-900 - latin-ext_latin */
// @font-face {
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 900;
// 	font-display: swap;
// 	src: local(""), url("../../assets/fonts/montserrat-v18-latin-ext_latin-900.woff2") format("woff2"),
// 		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../assets/fonts/montserrat-v18-latin-ext_latin-900.woff")
// 			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
