.yc-price-panel {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    margin-bottom: 26px;

    h6 {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font_700;
        color: var(--black);
        padding-right: 25px;
    }

    a {
        font-size: 12px;
        line-height: 15px;
        font-weight: $font_600;
        color: #b59436 !important;

        &::after {
            content: "\203A";
            margin-left: 4px;
            font-size: 17px;
        }
    }

    button {
        position: relative;
        margin-top: 25px;
        line-height: 16px;
        border: none;

        &:after {
            position: absolute;
            content: "";
            background-size: contain !important;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .yc-btn {
        // width: calc(100% - 90px);
        width: 100%;
        padding: 3px 30px 2px 23px;

        &:after {
            background: url("#{$icon-dw-ar-lg-light}") no-repeat;
            right: 12px;
            width: 14px;
            height: 12px;
            transform: rotate(-90deg);
        }

        &--payment {
            background: #448b09;

            &:after {
                content: none;
            }
        }
    }

    .yc-btn-back {
        width: 73px;
        height: 40px;
        margin-right: 17px;
        font-size: 14px;
        line-height: 18px;
        border: 1px solid #ceccc7;
        border-radius: 4px;
        background-color: var(--white);
        color: var(--black);
        padding: 1px 7px 0 17px;
        transition: 0.25s;

        &:after {
            background: url("#{$icon-left-arrow-dark-sm}") no-repeat;
            background-size: contain;
            left: 8px;
            width: 10px;
            height: 14px;
            transition: 0.25s;
        }

        &:hover {
            background: #fff1bd;
            color: #edb111;
            border: 1px solid #edb111;

            &:after {
                background: url("#{$icon-left-arrow-yellow}") no-repeat;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .yc-price-panel {
        padding: 40px 35px 33px;
    }
}

@include media-breakpoint-down(xl) {
    .yc-price-panel {
        .yc-btn-back,
        .yc-btn {
            font-size: 12px;
            line-height: inherit;
        }
    }
}

@include media-breakpoint-down(lg) {
    .yc-price-panel {
        padding: 20px;

        h6 {
            font-size: 14px;
            line-height: inherit;
            padding-right: 15px;
        }

        a {
            font-size: 10px;
            line-height: inherit;
        }

        button {
            height: 34px !important;
        }

        .yc-btn-back {
            margin-right: 10px;
            margin-top: 20px;

            &:after {
                left: 8px;
                width: 8px;
                height: 12px;
            }
        }

        .yc-btn {
            // width: calc(100% - 83px);
            margin-top: 20px;
            padding: 3px 20px 2px 10px;

            &:after {
                right: 20px;
                // right: 5px;
                // width: 12px;
                // height: 8px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .yc-price-panel {
        padding: 0 10px;
        box-shadow: none;
        margin-bottom: 0;
        // margin-top: 10px;
        margin-top: 25px;
        display: none;

        h6 {
            // font-size: 13px;
            // line-height: inherit;
            padding-right: 10px;
        }

        a {
            font-size: 10px;
            line-height: inherit;
        }

        button {
            &.yc-btn-back:after {
                left: 12px;
            }

            &.yc-btn-back,
            &.yc-btn {
                margin-top: 15px;
                // font-size: 9px;
                // line-height: inherit;
            }
        }

        &.stick {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            box-shadow: 0px 0px 6px #00000029;
            border-radius: 0;
            padding: 25px 32px 20px;
            z-index: 1;
        }
    }
}
