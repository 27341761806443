.sprite {
    background: url("#{$sprite-image}") center no-repeat;
    background-size: auto 200px;
}

%hycw-group {
    background-position: -4px -4px;
    width: 54px;
    height: 38px;
}

%hycw-task {
    background-position: -62px -4px;
    width: 39px;
    height: 48px;
}

%hycw-time {
    background-position: -105px -4px;
    width: 54px;
    height: 54px;
}

%hycw-card {
    background-position: -163px -4px;
    width: 54px;
    height: 39px;
}

%hycw-done {
    background-position: -221px -4px;
    width: 52px;
    height: 54px;
}

%facebook {
    background-position: -277px -4px !important;
    width: 9px;
    height: 16px;
}

%facebook-hover {
    background-position: -277px -24px !important;
    width: 9px;
    height: 16px;
}

%twitter {
    background-position: -290px -4px !important;
    width: 17px;
    height: 14px;
}

%twitter-hover {
    background-position: -290px -24px !important;
    width: 17px;
    height: 14px;
}

%insta {
    background-position: -311px -4px !important;
    width: 16px;
    height: 16px;
}

%insta-hover {
    background-position: -311px -24px !important;
    width: 16px;
    height: 16px;
}

%youtube {
    background-position: -331px -4px !important;
    width: 16px;
    height: 11px;
}

%youtube-hover {
    background-position: -331px -24px !important;
    width: 16px;
    height: 11px;
}

%lb {
    background-position: -351px -4px !important;
    width: 17px;
    height: 14px;
}

%lb-hover {
    background-position: -351px -24px !important;
    width: 17px;
    height: 14px;
}

%heart {
    background-position: -372px -4px !important;
    width: 18px;
    height: 17px;
}

%heart-hover {
    background-position: -372px -24px !important;
    width: 18px;
    height: 17px;
}

%task-back {
    background-position: -397px -3px;
    width: 10px;
    height: 18px;
}

%task-location {
    background-position: -392px -24px;
    width: 14px;
    height: 16px;
}

%task-plus {
    background-position: -420px -5px;
    width: 14px;
    height: 14px;
}

%task-calendar {
    background-position: -464px 0;
    width: 14px;
    height: 16px;
}

%task-clock {
    background-position: -479px 0;
    width: 16px;
    height: 16px;
}

%task-down-arrow {
    background-position: -446px -10px;
    width: 10px;
    height: 5px;
}

%task-star {
    background-position: -408px -25px;
    width: 14px;
    height: 14px;
}

%task-paginate-left {
    background-position: -424px -24px;
    width: 5px;
    height: 10px;
}

%task-paginate-right {
    background-position: -431px -24px;
    width: 5px;
    height: 10px;
}

%service-arrow {
    background-position: -437px -24px;
    width: 6px;
    height: 9px;
}

%map-box-location {
    background-position: -444px -24px;
    width: 15px;
    height: 16px;
}

%map-box-mail {
    background-position: -459px -25px;
    width: 16px;
    height: 14px;
}

%map-box-phone {
    background-position: -476px -24px;
    width: 16px;
    height: 16px;
}

%map-box-fb {
    background-position: -4px -56px;
    width: 24px;
    height: 24px;
}

%map-box-twitter {
    background-position: -32px -56px;
    width: 24px;
    height: 24px;
}

%map-box-insta {
    background-position: -60px -56px;
    width: 24px;
    height: 24px;
}

%map-box-wapp {
    background-position: -88px -56px;
    width: 24px;
    height: 24px;
}

%partner-quote {
    background-position: -4px -84px;
    width: 78px;
    height: 58px;
}

%header-task {
    background-position: -119px -64px;
    width: 18px;
    height: 22px;
}

%header-user {
    background-position: -142px -64px;
    width: 20px;
    height: 22px;
}

%header-dropdown-icon {
    background-position: -163px -48px;
    width: 10px;
    height: 5px;
}

%header-user-mobile {
    background-position: -206px -63px;
    width: 20px;
    height: 22px;
}

%header-task-mobile {
    background-position: -172px -59px;
    width: 24px;
    height: 30px;
}

%call-center-phone {
    background-position: -87px -92px;
    width: 30px;
    height: 30px;
}

%task-done {
    background-position: -177px -46px;
    width: 10px;
    height: 8px;
}

%visa {
    background-position: -231px -61px;
    width: 26px;
    height: 18px;
}

%yellow-close {
    background-position: -123px -92px;
    width: 32px;
    height: 32px;
}

%send-message {
    background-position: -261px -63px;
    width: 14px;
    height: 14px;
}

%location-search {
    background-position: -161px -95px;
    width: 22px;
    height: 22px;
}

%accordion-arrow {
    background-position: -277px -44px;
    width: 12px;
    height: 7px;
}
